const resumeContent = {
  name: "Yuk Kwan MA (Ennis)",
  contact: "+1 403-305-8680 · ennis214@gmail.com · Calgary, Alberta, Canada",

  summary: `
## Professional Summary

Full-stack Developer based in Calgary with expertise across web and mobile platforms. Proficient in JavaScript/TypeScript, Python, and modern frameworks including React, Next.js, and Flutter. Combines technical skills with business acumen gained from previous marketing experience to deliver solutions that align with both user needs and business objectives. Known for clean, maintainable code and a collaborative approach to problem-solving. Passionate about creating intuitive, high-performance applications with a focus on security and scalability.
`,

  professionalExperience: `
## Professional Experience

### Hustle Media Limited
**Full-Stack Developer** (Apr 2018 - Dec 2024)
- Architected and developed a comprehensive web platform for the Taipei City Government featuring interactive mapping and accessibility information, improving city services for residents and visitors
- Built and deployed multiple cross-platform applications for clients across Hong Kong and Taiwan, including e-commerce solutions, content management systems, and mobile apps
- Led technical collaboration with external partners, managing project timelines and ensuring deliverables met client specifications while maintaining code quality

### SAP Hong Kong Co. Limited
**Demand Generation Specialist** (Sept 2017 - Apr 2018)
- Orchestrated marketing events for enterprise software solutions, contributing directly to sales pipeline growth and increased market presence
- Developed relationships with C-level executives, translating technical capabilities into business value propositions
- Collaborated across marketing, sales, and product teams to create localized strategies, providing valuable insight into enterprise customer needs that now informs my development approach

### Infoware System Limited
**Sales Support Engineer** (Jan 2015 - Nov 2015)
- Provided technical pre-sales and implementation support for integrated business systems
- Collaborated with development teams to troubleshoot and integrate various technologies (CCTV, eBooking, CRM, iPOS)
- Gained hands-on experience with full software lifecycle from requirements gathering to deployment and support
`,

  education: `
## Education

- Southern Alberta Institute of Technology (SAIT)
  Diploma of Software Development (GPA 4.0)

- Hong Kong Institute of Vocational Education
  Higher Diploma of Network Applications
`,

  skills: `
## Technical Skills

- Programming Languages: 
  - JavaScript/TypeScript
  - Python
  - Dart
  - C#

- Frontend Development:
  - React/Next.js
  - Vue.js
  - HTML5/CSS3/Tailwind

- Mobile Development:
  - Flutter (iOS & Android)
  - React Native

- Backend & Databases:
  - Node.js/Express
  - MongoDB
  - MySQL/PostgreSQL
  - Firebase

- DevOps & Tools:
  - Git/GitHub
  - Docker
  - CI/CD Pipelines
  - AWS/GCP basics
`,

  projects: [
    {
      title: "ComfyUI Workflow Hub",
      description: `
- Built with Express.js backend and vanilla JavaScript frontend, integrated with ComfyUI through REST and WebSocket APIs
- Features workflow management system for AI image generation, supporting upload and execution of ComfyUI workflows with real-time progress tracking, custom inputs (text prompts, seed values, images), and result downloads
- Technologies used: Express.js, Node.js, WebSockets, Multer, REST APIs
      `,
      image: "project-images/comfyui-workflow-hub.png",
      link: "https://github.com/ennis-ma/ComfyUI-Workflow-Hub",
    },
    {
      title: "Taipei Friendly Store for Taipei City Government",
      description: `
- Built interactive map interface using Google Maps API with custom markers and info windows
- Implemented RESTful API endpoints using CodeIgniter framework for data retrieval and updates
- Created admin dashboard with CRUD operations for managing store listings and blog content
- Integrated MySQL database with optimized queries for fast data retrieval
- Technologies: PHP, CodeIgniter, jQuery, Google Maps API, MySQL
      `,
      image: "project-images/friendlystore-taipei.png",
      link: "https://friendlystore.taipei/index.php/en",
    },
    {
      title: "Myfan.tw E-commerce Platform",
      description: `
- Implemented server-side rendering with Next.js for optimal SEO and performance
- Built RESTful API with Express.js handling user authentication, orders, and payments
- Designed MongoDB schema with indexing for efficient querying and data relationships
- Integrated PayPal SDK for secure payment processing with webhook handling
- Set up Sanity CMS with custom schemas for content management
- Technologies: Next.js, TypeScript, Express.js, MongoDB, PayPal API, Sanity CMS
      `,
      image: "project-images/myfan.png",
      link: "https://myfan.tw",
    },
    {
      title: "Sealand E-commerce Websites",
      description: `
- Customized WordPress theme with WooCommerce integration for product management
- Implemented multiple payment gateways (Stripe, PayPal) with currency conversion
- Optimized site performance using caching and CDN integration
- Set up automated inventory sync between physical stores and online platform
- Technologies: WordPress, WooCommerce, PHP, MySQL, Stripe API
      `,
      image: "project-images/sealand-hk.png",
      link: "https://sealand-hk.com/",
    },
    {
      title: "Biddify Livestream Marketplace",
      description: `
- Built real-time bidding system using Firebase Cloud Functions and Firestore
- Integrated Agora SDK for low-latency video streaming with custom UI controls
- Implemented BLoC pattern for state management and dependency injection
- Created custom animations for bid updates and auction countdowns
- Set up Firebase Cloud Messaging for push notifications
- Technologies: Flutter, Firebase, Agora RTC, Cloud Functions, Cloud Firestore
      `,
      image: "project-images/biddify.png",
      link: "",
    },
    {
      title: "Maxfit Gym Booking App",
      description: `
- Developed booking system with Firebase real-time updates and conflict prevention
- Implemented user authentication with email and social login options
- Created custom Flutter widgets for calendar and booking interfaces
- Set up Cloud Functions for automated booking confirmations and reminders
- Technologies: Flutter, Firebase Auth, Cloud Firestore, Cloud Functions
      `,
      image: "project-images/maxfit.png",
      link: "",
    },
    {
      title: "Terminal-style Portfolio Website",
      description: `
- Built custom command interpreter using React hooks and context API
- Implemented virtual file system simulation with JSON-based structure
- Created custom terminal emulator with command history and auto-completion
- Added Markdown parsing for dynamic content rendering
- Technologies: React, TypeScript, Tailwind CSS, React Router
      `,
      image: "",
      link: "",
    },
  ],
};

// Generate the about section from existing data
resumeContent.about = `
# About ${resumeContent.name}

Driven and self-motivated Developer with a solid foundation in JavaScript, Python, Flutter and modern web
technologies like React, Vue, and GraphQL. Gained hands-on experience leading software development
projects during studies at SAIT, demonstrating strong problem-solving abilities and a keen eye for delivering
high-quality, maintainable code. Passionate about building performant and user-friendly web applications
with a focus on security and best practices. Excellent team player with exceptional communication and
organizational skills. Eager to contribute to innovative projects and continuously expand my skills in a
collaborative environment.

## Education

- Southern Alberta Institute of Technology (SAIT)
  Diploma of Software Development (GPA 4.0)

- Hong Kong Institute of Vocational Education
  Higher Diploma of Network Applications

When I'm not coding, you might find me exploring the beautiful landscapes around Calgary or experimenting with new technologies. I'm always open to connecting with fellow developers and potential collaborators in the Alberta tech community.
`;

// Generate the projects section
resumeContent.projectsSection = `
# Projects

${resumeContent.projects
  .map((project) => {
    let content = `
# ${project.title}

${project.description}`;

    if (project.image) {
      content += `\n\n![${project.title}](${project.image})`;
    }

    if (project.link) {
      content += `\n\n${project.link}`;
    }

    return content;
  })
  .join("\n")}`;

// Generate the full resume from individual parts
resumeContent.full = `
# ${resumeContent.name}

${resumeContent.contact}

${resumeContent.summary}

${resumeContent.professionalExperience}

${resumeContent.education}

${resumeContent.skills}

${resumeContent.projectsSection}
`;

export default resumeContent;
