import { useState, useEffect } from "react";
import { fileSystem, fileContents } from "../data/fileSystem";
import { commands } from "../data/commands";

const useTerminal = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState([
    'Welcome to my portfolio! Type "help" for available commands.',
  ]);
  const [currentPath, setCurrentPath] = useState("~");
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  // Helper function to resolve paths
  const resolvePath = (path) => {
    // If path starts with ~, use it as is
    if (path.startsWith("~/")) {
      return path;
    }
    // If path is absolute (starts with /), ignore current path
    if (path.startsWith("/")) {
      return path;
    }
    // Otherwise, join with current path
    // If we're at root and path doesn't have a leading slash
    if (currentPath === "~" && !path.startsWith("/")) {
      return `~/${path}`;
    }
    // Otherwise join normally
    return `${currentPath}/${path}`;
  };

  // Function to handle Tab autocompletion
  const handleTabCompletion = () => {
    // If input is empty, do nothing
    if (!input.trim()) return;

    const inputParts = input.split(" ");
    const lastPart = inputParts[inputParts.length - 1];
    
    // Case 1: First word - Try to autocomplete command
    if (inputParts.length === 1) {
      const matchingCommands = Object.keys(commands).filter(cmd => 
        cmd.startsWith(lastPart)
      );
      
      if (matchingCommands.length === 1) {
        // Exact match - complete the command
        setInput(matchingCommands[0]);
      } else if (matchingCommands.length > 1) {
        // Multiple matches - show options
        setOutput(prev => [
          ...prev, 
          `${currentPath} $ ${input}`,
          matchingCommands.join("  ")
        ]);
      }
    } 
    // Case 2: After 'cd' or 'cat' - Try to autocomplete directory or file
    else if (['cd', 'cat'].includes(inputParts[0])) {
      // Get items from current directory
      const items = fileSystem[currentPath] || [];
      
      // Filter matching items
      const matchingItems = items.filter(item => 
        item.startsWith(lastPart)
      );
      
      if (matchingItems.length === 1) {
        // Exact match - complete the path
        inputParts[inputParts.length - 1] = matchingItems[0];
        setInput(inputParts.join(" "));
      } else if (matchingItems.length > 1) {
        // Multiple matches - show options
        setOutput(prev => [
          ...prev, 
          `${currentPath} $ ${input}`,
          matchingItems.join("  ")
        ]);
      }
    }
  };

  const handleCommand = (cmd) => {
    const [command, ...args] = cmd.split(" ");
    
    switch (command) {
      case "help":
        return Object.entries(commands)
          .map(([cmd, desc]) => `${cmd}: ${desc}`)
          .join("\n");
          
      case "ls": {
        // Check if we're trying to list a specific directory
        let pathToList = currentPath;
        
        if (args.length > 0) {
          // If arg starts with ~, use it as absolute path
          if (args[0].startsWith("~")) {
            pathToList = args[0];
          } else {
            // Otherwise, resolve relative to current path
            pathToList = resolvePath(args[0]);
          }
        }
        
        // Check if directory exists
        if (fileSystem[pathToList]) {
          return fileSystem[pathToList].join("\n");
        }
        
        return `Directory not found: ${args[0]}`;
      }
      
      case "cd": {
        if (!args[0]) {
          // cd with no args goes to home
          setCurrentPath("~");
          return `Changed directory to ~`;
        }
          
        if (args[0] === "..") {
          if (currentPath !== "~") {
            setCurrentPath("~");
            return `Changed directory to ~`;
          }
          return "Already at root directory";
        }
        
        // Resolve the path (handle both absolute and relative paths)
        let newPath;
        if (args[0].startsWith("~")) {
          newPath = args[0];
        } else {
          newPath = resolvePath(args[0]);
        }
        
        if (fileSystem[newPath]) {
          setCurrentPath(newPath);
          return `Changed directory to ${newPath}`;
        }
        
        return `Directory not found: ${args[0]}`;
      }
      
      case "cat": {
        if (!args[0]) {
          return "Please specify a file to read";
        }
        
        // Resolve the file path
        let filePath;
        if (args[0].startsWith("~")) {
          filePath = args[0];
        } else {
          filePath = resolvePath(args[0]);
        }
        
        if (fileContents[filePath]) {
          return { markdown: fileContents[filePath] };
        }
        
        return `File not found: ${args[0]}`;
      }
      
      case "clear":
        setOutput([]);
        return null;
        
      default:
        return `Command not found: ${command}. Type "help" for available commands.`;
    }
  };

  // New method to execute commands programmatically (for GUI buttons)
  const executeCommand = (cmd) => {
    const result = handleCommand(cmd);
    if (result !== null) {
      setOutput((prevOutput) => [
        ...prevOutput,
        `${currentPath} $ ${cmd}`,
        result,
      ]);
    }
    setCommandHistory((prev) => [...prev, cmd]);
    setHistoryIndex(-1);
    setInput("");
  };

  const handleInput = (e) => {
    if (e.key === "Enter") {
      const result = handleCommand(input);
      if (result !== null) {
        setOutput((prevOutput) => [
          ...prevOutput,
          `${currentPath} $ ${input}`,
          result,
        ]);
      }
      setCommandHistory((prev) => [...prev, input]);
      setHistoryIndex(-1);
      setInput("");
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        setHistoryIndex((prevIndex) => prevIndex + 1);
        setInput(commandHistory[commandHistory.length - 1 - historyIndex - 1]);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (historyIndex > -1) {
        setHistoryIndex((prevIndex) => prevIndex - 1);
        setInput(
          historyIndex === 0
            ? ""
            : commandHistory[commandHistory.length - 1 - historyIndex + 1]
        );
      }
    } else if (e.key === "Tab") {
      // Prevent default tab behavior (focus navigation)
      e.preventDefault();
      // Handle autocomplete
      handleTabCompletion();
    }
  };

  useEffect(() => {
    const terminal = document.getElementById("terminal-output");
    terminal.scrollTop = terminal.scrollHeight;
  }, [output]);

  return { input, setInput, output, currentPath, handleInput, executeCommand };
};

export default useTerminal;
