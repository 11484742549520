import resumeContent from "./resumeContent";

export const fileSystem = {
  "~": ["documents", "projects"],
  "~/documents": [
    "resume.md",
    "about.md",
    "skills.md",
    "projects.md",
    "experience.md",
  ],
  "~/projects": [
    "taipei_friendly_store.md",
    "ecommerce_websites.md",
    "terminal_portfolio.md",
    "gym_booking_app.md",
    "myfan_tw.md",
  ],
};

export const fileContents = {
  "~/documents/resume.md": resumeContent.full,
  "~/documents/about.md": resumeContent.about,
  "~/documents/skills.md": resumeContent.skills,
  "~/documents/projects.md": resumeContent.projectsSection,
  "~/documents/experience.md": resumeContent.professionalExperience,
  "~/projects/taipei_friendly_store.md": `# ${
    resumeContent.projects[0].title
  }\n\n${resumeContent.projects[0].description}${
    resumeContent.projects[0].image
      ? `\n\n![${resumeContent.projects[0].title}](${resumeContent.projects[0].image})`
      : ""
  }${
    resumeContent.projects[0].link
      ? `\n\n${resumeContent.projects[0].link}`
      : ""
  }`,
  "~/projects/gym_booking_app.md": `# ${resumeContent.projects[1].title}\n\n${
    resumeContent.projects[1].description
  }${
    resumeContent.projects[1].image
      ? `\n\n![${resumeContent.projects[1].title}](${resumeContent.projects[1].image})`
      : ""
  }${
    resumeContent.projects[1].link
      ? `\n\n${resumeContent.projects[1].link}`
      : ""
  }`,
  "~/projects/myfan_tw.md": `# ${resumeContent.projects[2].title}\n\n${
    resumeContent.projects[2].description
  }${
    resumeContent.projects[2].image
      ? `\n\n![${resumeContent.projects[2].title}](${resumeContent.projects[2].image})`
      : ""
  }${
    resumeContent.projects[2].link
      ? `\n\n${resumeContent.projects[2].link}`
      : ""
  }`,
  "~/projects/terminal_portfolio.md": `# ${
    resumeContent.projects[3].title
  }\n\n${resumeContent.projects[3].description}${
    resumeContent.projects[3].image
      ? `\n\n![${resumeContent.projects[3].title}](${resumeContent.projects[3].image})`
      : ""
  }${
    resumeContent.projects[3].link
      ? `\n\n${resumeContent.projects[3].link}`
      : ""
  }`,
  "~/projects/ecommerce_websites.md": `# ${
    resumeContent.projects[4].title
  }\n\n${resumeContent.projects[4].description}${
    resumeContent.projects[4].image
      ? `\n\n![${resumeContent.projects[4].title}](${resumeContent.projects[4].image})`
      : ""
  }${
    resumeContent.projects[4].link
      ? `\n\n${resumeContent.projects[4].link}`
      : ""
  }`,
};
