import React, { useRef, useState } from "react";
import {
  Terminal,
  HelpCircle,
  User,
  Briefcase,
  Code,
  FileText,
  Home,
  Github,
  Building,
} from "lucide-react";
import useTerminal from "../hooks/useTerminal";
import { renderMarkdown } from "../utils/markdownRenderer";
import ennisImage from "../assets/ennis.jpeg";

const TerminalPortfolio = () => {
  const { input, setInput, output, currentPath, handleInput, executeCommand } =
    useTerminal();
  const inputRef = useRef(null);
  const [showHelp, setShowHelp] = useState(false);

  // Quick welcome card with summary
  const WelcomeCard = () => (
    <div className="bg-gray-900 border border-green-500 p-4 rounded-md mb-4">
      <div className="flex flex-col items-start mb-3">
        <img
          src={ennisImage}
          alt="Ennis Ma"
          className="w-40 h-40 mb-4 object-cover border-2 border-green-400"
        />
        <div>
          <h2 className="text-green-400 text-lg font-bold mb-2">
            Hi, I'm Ennis Ma 👋
          </h2>
          <p className="text-green-300 mb-2">
            👨‍💻 Full-stack Developer based in 🇨🇦 Calgary with experience across
            web and mobile platforms. CLI &#62; GUI
          </p>
          <p className="text-green-300 mb-2">
            <span className="flex items-center">
              <Github size={16} className="mr-1" />
              GitHub:{" "}
              <a
                href="https://github.com/ennis-ma"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-400 hover:underline ml-1"
              >
                @ennis-ma
              </a>
            </span>
          </p>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 mb-3">
        <span className="bg-green-900 text-green-300 text-xs px-2 py-1 rounded">
          React
        </span>
        <span className="bg-green-900 text-green-300 text-xs px-2 py-1 rounded">
          TypeScript
        </span>
        <span className="bg-green-900 text-green-300 text-xs px-2 py-1 rounded">
          Next.js
        </span>
        <span className="bg-green-900 text-green-300 text-xs px-2 py-1 rounded">
          Flutter
        </span>
        <span className="bg-green-900 text-green-300 text-xs px-2 py-1 rounded">
          MongoDB
        </span>
        <span className="bg-green-900 text-green-300 text-xs px-2 py-1 rounded">
          Python
        </span>
      </div>
      <p className="text-green-200 text-sm italic">
        Use the terminal below to navigate my portfolio, or click the command
        buttons for quick access.
      </p>
    </div>
  );

  // Help popup
  const HelpPopup = () => (
    <div className="absolute top-12 right-4 bg-gray-900 border border-green-500 p-4 rounded-md shadow-lg z-10 max-w-md">
      <h3 className="text-green-400 font-bold mb-2">Terminal Commands:</h3>
      <ul className="text-green-300 text-sm mb-3">
        <li>
          <span className="text-green-400 font-mono">help</span> - Show
          available commands
        </li>
        <li>
          <span className="text-green-400 font-mono">ls</span> - List files in
          current directory
        </li>
        <li>
          <span className="text-green-400 font-mono">cd [directory]</span> -
          Change directory
        </li>
        <li>
          <span className="text-green-400 font-mono">cat [file]</span> - View
          file contents
        </li>
        <li>
          <span className="text-green-400 font-mono">clear</span> - Clear
          terminal output
        </li>
      </ul>
      <p className="text-green-200 text-xs">
        Pro tip: Use TAB to autocomplete commands and filenames!
      </p>
      <button
        className="text-green-400 hover:text-green-300 text-sm mt-2"
        onClick={() => setShowHelp(false)}
      >
        Close
      </button>
    </div>
  );

  const handleContainerClick = () => {
    inputRef.current.focus();
  };

  const handleCommandClick = (command) => {
    // Clear the terminal first
    executeCommand("clear");
    // Then execute the requested command
    setInput(command);
    executeCommand(command);

    // Scroll to top of terminal output
    setTimeout(() => {
      const terminal = document.getElementById("terminal-output");
      if (terminal) {
        terminal.scrollTop = 0;
      }
    }, 50); // Small delay to ensure content is rendered
  };

  // Command button component
  const CommandButton = ({ icon, command, label }) => (
    <button
      className="flex items-center bg-green-900 text-green-300 px-3 py-1 rounded hover:bg-green-800 transition-colors"
      onClick={() => handleCommandClick(command)}
    >
      {icon}
      <span className="ml-1 text-sm">{label}</span>
    </button>
  );

  return (
    <div
      className="bg-black text-green-500 p-4 font-mono h-screen flex flex-col relative"
      onClick={handleContainerClick}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <Terminal className="mr-2" />
          <h1 className="text-xl font-bold">Ennis Ma Portfolio</h1>
        </div>
        <button
          className="text-green-400 hover:text-green-300"
          onClick={() => setShowHelp(!showHelp)}
          aria-label="Help"
        >
          <HelpCircle size={20} />
        </button>
      </div>

      {/* Show welcome card at the top */}
      <WelcomeCard />

      {/* Command shortcuts */}
      <div className="flex flex-wrap gap-2 mb-4">
        <CommandButton
          icon={<FileText size={14} />}
          command="cat ~/documents/resume.md"
          label="Full Resume"
        />
        <CommandButton
          icon={<User size={14} />}
          command="cat ~/documents/about.md"
          label="About Me"
        />
        <CommandButton
          icon={<Building size={14} />}
          command="cat ~/documents/experience.md"
          label="Experience"
        />
        <CommandButton
          icon={<Code size={14} />}
          command="cat ~/documents/skills.md"
          label="Skills"
        />
        <CommandButton
          icon={<Briefcase size={14} />}
          command="cat ~/documents/projects.md"
          label="Projects"
        />
        <CommandButton icon={<Home size={14} />} command="cd ~" label="Home" />
      </div>

      <div
        id="terminal-output"
        className="flex-grow overflow-y-auto mb-4 border border-gray-800 rounded p-2 bg-black"
      >
        {output.map((line, index) => (
          <div key={index} className="whitespace-pre-wrap">
            {typeof line === "string"
              ? line
              : line.markdown
              ? renderMarkdown(line.markdown)
              : line}
          </div>
        ))}
      </div>
      <div className="flex items-center bg-gray-900 p-2 rounded">
        <span className="mr-2 text-green-400">{currentPath} $</span>
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleInput}
          className="bg-transparent border-none outline-none flex-grow text-green-300"
          autoFocus
        />
      </div>

      {/* Help popup */}
      {showHelp && <HelpPopup />}
    </div>
  );
};

export default TerminalPortfolio;
