import React from "react";

export const renderMarkdown = (markdown) => {
  const lines = markdown.split("\n");
  return lines.map((line, index) => {
    // Handle image markdown ![alt](url)
    if (line.trim().match(/^!\[.*\]\(.*\)$/)) {
      const alt = line.match(/!\[(.*)\]/)[1];
      const url = line.match(/\((.*)\)/)[1];
      return (
        <div key={index} className="my-3">
          <img 
            src={url} 
            alt={alt} 
            className="border border-green-500 max-w-full h-auto rounded-md" 
            style={{ maxHeight: '300px' }}
          />
        </div>
      );
    }
    // Handle headings
    else if (line.startsWith("# ")) {
      return (
        <h1 key={index} className="text-xl font-bold mt-2 mb-1 text-green-400">
          {line.substring(2)}
        </h1>
      );
    } else if (line.startsWith("## ")) {
      return (
        <h2 key={index} className="text-lg font-bold mt-2 mb-1 text-green-400">
          {line.substring(3)}
        </h2>
      );
    } else if (line.startsWith("### ")) {
      return (
        <h3 key={index} className="text-md font-bold mt-2 mb-1 text-green-400">
          {line.substring(4)}
        </h3>
      );
    } 
    // Handle links
    else if (line.trim().match(/^https?:\/\/.+$/)) {
      return (
        <a 
          key={index} 
          href={line.trim()} 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-blue-400 hover:underline"
        >
          {line.trim()}
        </a>
      );
    }
    // Handle lists
    else if (line.startsWith("- ")) {
      return (
        <li key={index} className="ml-4 text-green-300">
          {line.substring(2)}
        </li>
      );
    } 
    // Handle empty lines
    else if (line.trim() === "") {
      return <br key={index} />;
    } 
    // Handle regular paragraphs with bold formatting
    else {
      const boldRegex = /\*\*(.*?)\*\*/g;
      const parts = line.split(boldRegex);
      return (
        <p key={index} className="text-green-300">
          {parts.map((part, i) =>
            i % 2 === 0 ? part : <strong key={i}>{part}</strong>
          )}
        </p>
      );
    }
  });
};
